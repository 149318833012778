<template>
  <Card class="shadow-none">
    <template v-slot:title><h4>Locação:</h4></template>
    <template v-slot:subtitle> </template>
    <template v-slot:content>
      <div class="p-fluid">
        <div class="p-grid">
          <div class="p-col-12 p-md-6 p-field">
            <label for="imovel">Imóvel</label>
            <div class="p-inputgroup">
              <AutoComplete
                id="imovel"
                v-model="imovel"
                :suggestions="imovelResultados"
                @complete="filtrarAutoComplete($event)"
                @blur="validaAutoComplete('imovel')"
                field="nome"
                placeholder="Informe o código, nome ou endereço do imóvel"
                :class="
                  fieldsError.some((v) => v === 'imovel') ? 'p-invalid' : ''
                "
              />
              <Button
                label="cadastrar"
                class="p-button-warning"
                @click="imoveis_edit = true"
              />
            </div>
          </div>
          <div class="p-col-12 p-md-6 p-field">
            <label for="locacao_finalidade">Finalidade</label>
            <SelectButton
              id="locacao_finalidade"
              v-model="locacao_finalidade"
              :options="finalidades"
              optionLabel="nome"
              optionValue="id"
              :class="
                fieldsError.some((v) => v === 'locacao_finalidade')
                  ? 'p-invalid'
                  : ''
              "
            />
          </div>

        </div>

        <div class="p-grid">
          <div class="p-col-12 p-md-3 p-field p-d-none">
            <label for="locacao_motivo" class="p-d-block"
              >Motivo da Locação</label
            >
            <Dropdown
              id="locacao_motivo"
              v-model="locacao_motivo"
              :class="
                fieldsError.some((v) => v === 'locacao_motivo')
                  ? 'p-invalid'
                  : ''
              "
              :options="motivos"
              optionLabel="nome"
              optionValue="id"
              placeholder="Selecione"
            />
          </div>

          <div class="p-col-12 p-md-3 p-field" v-show="mostrar_pj">
            <label for="locacao_empresa_ramo_atividade" class="p-d-block"
              >Ramo de Atividade</label
            >
            <Dropdown
              id="locacao_empresa_ramo_atividade"
              v-model="locacao_empresa_ramo_atividade"
              :class="
                fieldsError.some((v) => v === 'locacao_empresa_ramo_atividade')
                  ? 'p-invalid'
                  : ''
              "
              :options="ramos_atividades"
              optionLabel="nome"
              optionValue="id"
              placeholder="Selecione"
            />
          </div>

          <div class="p-field p-col-12 p-md-3" v-show="mostrar_pj">
            <label for="locacao_empresa_cnpj"
              >CNPJ (caso a empresa já possua)</label
            >
            <InputMask
              id="locacao_empresa_cnpj"
              mask="99.999.999/9999-99"
              v-model="locacao_empresa_cnpj"
              @focus="cnpjInvalido = false"
              :class="
                fieldsError.some((v) => v === 'locacao_empresa_cnpj')
                  ? 'p-invalid'
                  : ''
              "
            />
            <InlineMessage v-if="cnpjInvalido">CNPJ Inválido</InlineMessage>
          </div>
        </div>

        <div class="p-grid">

          <div class="p-col-12 p-mb-4">
            <DataTable sortMode="single" :value="imoveis">
              <Column field="nome" headerClass="colNome" header="Nome">
              </Column>

              <Column headerStyle="width:150px">
                <template #body>
                  <Button 
                    v-tooltip.top="'Ver Detalhes'"
                    icon="pi pi-eye" 
                    class="p-mb-2 p-button-info p-mr-2"
                    @click="imovel_view = true"
                  />
                  <Button
                    v-tooltip.top="'Remover'"
                    icon="pi pi-trash"
                    class="p-mr-2 p-mb-2 p-button-danger"
                    @click="removerImovel($event)"
                  ></Button>
                </template>
              </Column>
              <template #empty> Nenhum imóvel incluído. </template>
            </DataTable>
          </div>

        </div>

      </div>
    </template>
    <template v-slot:footer>
      <div class="p-d-block p-text-right">
        <Button
          label="Próximo"
          @click="nextPage()"
          icon="pi pi-angle-right"
          iconPos="right"
        />
      </div>
    </template>
  </Card>

  <Dialog v-model:visible="imoveis_edit" style="max-width: 98%; width: 1000px" :modal="true">
    <ImoveisEdit @save="imovelCadastrado" :imobiliaria="imobiliaria" />
  </Dialog>

  <Dialog v-model:visible="imovel_view" style="max-width: 98%; width: 800px" :modal="true" :closable="false">
    <template #header>
      <h5 class="p-m-0"><strong>Confirma a seleção deste imóvel?</strong></h5>
    </template>
    <div class="p-mb-4 p-text-center">
      <Button
        class="p-button-success"
        label="Está correto!"
        icon="pi pi-check"
        iconPos="right"
        @click="imovel_view = false"
      />
      <Button
        class="p-button-warning p-ml-4"
        label="Cadastrar novo imóvel"
        icon="pi pi-plus"
        iconPos="right"
        @click="imovel = null; imovel_view = false; imoveis_edit = true"
      />
    </div>
    <ImoveisView :imovel="imovel.id" />
  </Dialog>

</template>

<script>
import ImoveisEdit from "../../imoveis/ImoveisEdit.vue";
import ImoveisView from "../../imoveis/ImoveisView.vue";

export default {
  props: {
    errorData: Array,
    processo: Object,
    imobiliaria: Number
  },
  emits: [
    "prev-page",
    "next-page",
    "complete"
  ],
  data() {
    return {

      loading: 3,

      // Validações
      fieldsError: [],
      cnpjInvalido: false,

      //Regras de apresentação
      imoveis_edit: false,
      mostrar_pj: false,
      imovel_view: false,

      // AUTOCOMPLETE OBRIGATORIOS: //
      imovelResultados: [],
      // FIM AUTOCOMPLETE //

      // Informações do Formulário
      imovel: null, //Resposta do AutoComplete
      imoveis: [], //Imoveis selecionados
      locacao_finalidade: null,
      locacao_motivo: 13, // TODO Verificar melhor depois
      locacao_empresa_ramo_atividade: null,
      locacao_empresa_cnpj: "",

      // Chaves estrangeiras
      motivos: [],
      finalidades: [],
      ramos_atividades: [],
    };
  },
  mounted() {

    const self = this;

    this.$api.get("/locacoes-motivos").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.motivos = dados.data;
        self.loading--;
      }

    });

    this.$api.get("/locacoes-finalidades").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.finalidades = dados.data;
        self.loading--;
      }
    });

    this.$api.get("/pessoas-ramos-atividades").then(function (response) {
      const dados = response.data;

      if (dados.success) {
        self.ramos_atividades = dados.data;
        self.loading--;
      }
    });

    this.fieldsError = this.errorData;

  },
  watch: {

    processo: function (p) {

      const self = this;

      var interval = setInterval(() => {
        if(self.loading == 0) {
          clearInterval(interval);

          self.imovelCadastrado(p.imovel_item);
          self.locacao_finalidade = p.locacao_finalidade;
          self.locacao_motivo = p.locacao_motivo;
          self.locacao_empresa_ramo_atividade = p.locacao_empresa_ramo_atividade;
          self.locacao_empresa_cnpj = p.locacao_empresa_cnpj;

        }
      },300);

    },

    errorData: function() {
      this.fieldsError = this.errorData;
    },

    locacao_finalidade: function (val) {
      if (val) {
        const f = this.finalidades.filter((obj) => obj.id == val)[0];
        this.mostrar_pj = f.possui_outro == 1;
      } else {
        this.mostrar_pj = false;
      }
    },

    locacao_empresa_cnpj: function (val) {
      if(typeof val == "string") {
        if (val.length == 18) {
          this.cnpjInvalido = !this.$utils.isCNPJ(val);
        } else if (val.length > 0) {
          this.cnpjInvalido = true;
        }
      }
    },

    imovel: function (val) {
      if(typeof val == "object" && val != null) {
        this.imovel_view = true;
        this.imoveis = [val];
      } else {
        this.imoveis = [];
      }
    }

  },
  methods: {

    imovelCadastrado(imovel) {      

      let identificacao = [
        imovel.id_proprio,
        imovel.nome
      ].filter(Boolean).join(" - ");

      let endereco = [
        imovel.logradouro,
        imovel.numero,
        imovel.complemento,
        imovel.bairro,
        imovel.cidade,
        imovel.uf,
      ].filter(Boolean).join(", ");

      if(identificacao != "")
        imovel.nome = identificacao + " - ";
      
      imovel.nome = endereco;

      this.imovelResultados = [imovel];
      this.imovel = imovel;

      this.imoveis_edit = false;

    },

    validaAutoComplete(val) {
      if(typeof this[val] != "object")
        this[val] = null;
    },

    nextPage() {
      if (this.validateForm()) {
        let formData = {
          imovel: this.imovel.id,
          locacao_finalidade: this.locacao_finalidade,
        };

        const f = this.finalidades.filter(
          (obj) => obj.id == this.locacao_finalidade
        )[0];

        if (f.possui_outro) {
          formData["locacao_motivo"] = this.locacao_motivo;
          formData["locacao_empresa_ramo_atividade"] = this.locacao_empresa_ramo_atividade;
          formData["locacao_empresa_cnpj"] = this.$utils.emptyStr(this.locacao_empresa_cnpj);
        }

        this.$emit("next-page", { formData: formData, pageIndex: 0 });
      }
    },

    filtrarAutoComplete: function (event) {
      const self = this;

      const q = event.query.toLowerCase();

      this.$api
        .get(
          "/imoveis?fields=id_proprio,nome,logradouro,numero,complemento,bairro,cidade,uf&filters=" +
            '{"str":["' +
            q +
            '"],"status":["ATIVO"], "imobiliaria": [' + this.imobiliaria + ']}'
        )
        .then(function (response) {
          let dados = response.data;
          if (dados.success) {
            for (let i = 0; i < dados.data.length; i++) {

              let identificacao = [];

              if(typeof dados.data[i].id_proprio == "string" && dados.data[i].id_proprio != "")
                identificacao.push(dados.data[i].id_proprio);

              if(typeof dados.data[i].nome == "string" && dados.data[i].nome != "")
                identificacao.push(dados.data[i].nome);

              identificacao = (identificacao.length > 0)?identificacao.join(" - "):"";

              let endereco = [
                dados.data[i].logradouro,
                dados.data[i].numero,
                dados.data[i].complemento,
                dados.data[i].bairro,
                dados.data[i].cidade,
                dados.data[i].uf,
              ].filter(Boolean).join(", ");

              dados.data[i].nome = (identificacao != "")?identificacao + " - ":"";
              
              dados.data[i].nome += endereco;

            }

            self.imovelResultados = dados.data;
          }
        });
    },

    validateForm() {

      this.locacao_motivo = 13; // TODO Verificar melhor depois

      const self = this;

      this.fieldsError = [];

      if (
        typeof this.imovel != "object" ||
        this.imovel == null ||
        typeof this.imovel.id != "number"
      ) {
        this.fieldsError.push("imovel");
      }

      if (
        typeof this.locacao_finalidade != "number" ||
        this.locacao_finalidade <= 0
      ) {
        this.fieldsError.push("locacao_finalidade");
      } else {
        const finalidade = self.finalidades.filter(
          (obj) => obj.id == self.locacao_finalidade
        )[0];

        if (finalidade.possui_outro) {
          if (
            typeof this.locacao_empresa_ramo_atividade != "number" ||
            this.locacao_empresa_ramo_atividade <= 0
          ) {
            this.fieldsError.push("locacao_empresa_ramo_atividade");
          }
        }
      }

      if (
        typeof this.locacao_motivo != "number" ||
        this.locacao_motivo <= 0
      ) {
        this.fieldsError.push("locacao_motivo");
      }

      if (this.fieldsError.length) {
        self.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Preencha corretamente todas as informações!",
          life: 5000,
        });

        return false;
      }

      return true;
    },

    removerImovel(event) {
      const self = this;
      this.$confirm.require({
        target: event.currentTarget,
        message: "Tem certeza que deseja excluir?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          self.imovel = null;
        },
      });
    }

  },
  components: {
    ImoveisEdit,
    ImoveisView
  },
};
</script>

<style scoped>
:deep(.p-selectbutton .p-button.p-highlight) {
  background: #6d6c6c;
  border-color: #666;
}
</style>